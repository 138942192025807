var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hotels-column"},_vm._l((_vm.hotels),function(hotel,index){return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(isVis => _vm.loadViewportedHotel(hotel, isVis)),expression:"isVis => loadViewportedHotel(hotel, isVis)"}],key:hotel.id,staticClass:"hotels-column__cell"},[_c('div',{staticClass:"hotels-column__head",on:{"click":function($event){return _vm.handleClick(hotel.id, index)}}},[_c('div',{class:[
                    'hotels-column__label',
                    'hotels-column__label--hotel-name',
                ]},[_c('i',{staticClass:"el-icon-arrow-right",class:{
                        rotate: _vm.isShow(hotel.id),
                        visible: !_vm.isNoData(hotel.id)
                    }}),_c('span',{staticClass:"hotels-column__link",attrs:{"title":hotel.name},domProps:{"innerHTML":_vm._s(hotel.name)},on:{"click":function($event){$event.preventDefault();return _vm.goToHotel(hotel)}}})]),_c('div',{class:[
                    'hotels-column__label',
                    'hotels-column__label--score',
                ],domProps:{"innerHTML":_vm._s(!hotel.isCompetitor ? hotel.totalScore : '')}}),_c('div',{class:{
                    'hotels-column__label': true,
                    'hotels-column__label--change': true,
                    'hotels-column__label--green': Math.round(hotel.newTotalScore) > 0,
                    'hotels-column__label--red': Math.round(hotel.newTotalScore) < 0,
                },domProps:{"innerHTML":_vm._s(_vm.scoreDiff(hotel.newTotalScore))}})]),(_vm.isShow)?_c('div',{staticClass:"competitors",class:{show: _vm.isShow(hotel.id)}},_vm._l((_vm.competitors),function(item,key){return _c('div',{key:key,staticClass:"competitors__item"},[_c('span',{attrs:{"title":item}},[_vm._v(_vm._s(item))])])}),0):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }