
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import type HelperService from '@/modules/common/services/helper.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import type ClusterService from '@/modules/cluster/cluster.service';

@Component({
    filters: { PercentFilter, PriceFilter },
})
export default class PriceData extends Vue {
    @inject(KEY.HelperService) helperService!: HelperService;
    @inject(EventsManagerServiceS) eventsManagerService!: EventsManagerService;
    @inject(KEY.ClusterRatesService) clusterRatesService!: ClusterRatesService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;

    @Prop({ type: Object, required: true })
    priceData!: IPriceData | null;

    @Prop({ type: Boolean, required: true })
    hasBorder!: boolean;

    @Prop({ type: Date, required: false })
    date!: Date;

    @Prop({ type: Number })
    cellIndex!: number;

    get isHaveHolidayEvents() {
        if (!this.date) return false;
        return this.eventsManagerService.hasDateHolidayEvents(this.date);
    }

    get isHaveLocalEvents() {
        if (!this.date) return false;
        return this.eventsManagerService.hasDateOtherEvents(this.date, this.priceData?.hotelId);
    }

    get isLoading() {
        const data = this.clusterService.getHotelData(this.priceData?.hotelId!);
        return !data || !data?.documentLoaded;
    }

    get isFirstCell() {
        return this.date && this.date.getDate() === 1;
    }

    onHover() {
        if (!this.priceData) return;

        const { hotelId } = this.priceData!;
        const { priceCell: componentContainer } = this.$refs;
        const { cellIndex } = this;
        this.$emit('hover', { cellIndex, hotelId, componentContainer });
    }

    onOut() {
        this.$emit('hover', false);
    }
}
