

import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import type Day from '@/modules/common/types/day.type';
import PriceData from '@/modules/cluster/components/rates/table/price-data.vue';
import RatesClusterTableTooltip from '@/modules/cluster/components/rates/table/table-tooltip.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import type HelperService from '@/modules/common/services/helper.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';

import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import RatesCheckinDayModel from '@/modules/rates/models/rates-checkin-day.model';

@Component({
    components: { PriceData, RatesClusterTableTooltip },
    filters: {
        PercentFilter,
        PriceFilter,
    },
})
export default class DayPricesColumn extends Vue {
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(KEY.ClusterRatesService) clusterRatesService!: ClusterRatesService;
    @inject(KEY.ClusterService) clusterService!: ClusterService;
    @inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @inject(KEY.HelperService) helperService!: HelperService;
    @inject(EventsManagerServiceS) eventsManagerService!: EventsManagerService;

    @Prop({ type: Number, required: true })
    day!: Day;

    @Prop({ type: Array, required: true })
    prices!: IPriceData[];

    @Prop({ type: Number, required: false })
    hotelId!: number | null;

    @Prop({ type: Number, required: false })
    indexTab!: number;

    @Prop({ type: Boolean })
    firstDocLoaded!: boolean;

    private activeHotelId: number | null = null;
    private tooltipTop: number | null = null;
    private currentCellIndex: number | null = null;

    get clickedHotel() {
        return this.hotelId;
    }

    get isLastDayOfMonth() {
        const { lastDayCurrentMonth } = this.documentFiltersService;
        return lastDayCurrentMonth === this.day;
    }

    get date() {
        const { month, year } = this.documentFiltersService;
        return new Date(Date.UTC(year, month, this.day));
    }

    get isPast() {
        return this.documentFiltersService.isPreviousDay(this.day);
    }

    handleClick(hotelId: number, index: number) {
        this.$emit('click-row', { hotelId, index });
    }

    isShow(hotelId: number) {
        return hotelId === this.clickedHotel;
    }

    onHoverPrices(data: { cellIndex: number, hotelId: number, componentContainer: HTMLDivElement } | false) {
        if (!data) {
            this.activeHotelId = null;
            this.tooltipTop = null;
            return;
        }

        const { cellIndex, hotelId, componentContainer } = data;
        const cell = (this.$refs.priceCell as HTMLDivElement[])[cellIndex];

        this.currentCellIndex = cellIndex;

        if (cell && this.day) {
            if (!hotelId) {
                return;
            }

            const isNoData = this.clusterRatesService
                .isNoData(this.day, hotelId);

            if (this.activeHotelId !== hotelId && !isNoData) {
                this.activeHotelId = hotelId;
                this.tooltipTop = cell.offsetTop + componentContainer.offsetTop + componentContainer.offsetHeight;
            }
        } else {
            this.activeHotelId = null;
        }
    }

    getCompetitors(hotelId: number) {
        return this.clusterRatesService.getCompetitors(hotelId) || [];
    }

    getPrice(hotelId: number, competitorId?: number) {
        const { day } = this;
        const priceData: IPriceData = {
            isNoData: false,
            isNa: false,
            isSoldOut: false,
            isOutOfRange: false,
            isCompetitor: true,
            competitionPercent: null,
            price: null,
            currency: null,
            hotelId,
            compsetId: '',
            pax: null,
        };

        const mainCompsetData = this.clusterService
            .getMainCompsetData<RatesCompsetMainModel>(hotelId);

        if (!mainCompsetData) {
            priceData.isOutOfRange = true;
            return priceData;
        }

        if (this.clusterRatesService.isNoData(day, hotelId)) {
            priceData.isNoData = true;

            return priceData;
        }

        if (this.clusterRatesService.isNa(day, hotelId, competitorId)) {
            priceData.isNa = true;

            return priceData;
        }

        priceData.price = this.clusterRatesService
            .getPrice(this.day, hotelId, competitorId);

        const cd = this.clusterRatesService.getCheckinDay(this.day, hotelId) as RatesCheckinDayModel;

        priceData.pax = competitorId
            ? cd.hotels[competitorId]?.pax
            : null;

        if (priceData.price === 0) {
            priceData.isSoldOut = true;
        }

        priceData.competitionPercent = this.clusterRatesService
            .getCompetitionPercent(this.day, hotelId);
        priceData.currency = this.ratesCommonService
            .currency(mainCompsetData);
        priceData.color = this.clusterRatesService
            .getColor(this.day, hotelId);

        return priceData;
    }

    async openDailyPopup(index: number) {
        const price = this.prices[index];

        if (!price.hotelId || price.isOutOfRange) return;

        await this.$router.push({
            name: `${this.$route.name!}.day-rate`,
            params: {
                day: String(this.day),
                hotelId: String(price.hotelId),
                compsetId: String(price.compsetId),
            },
        });
    }
}
